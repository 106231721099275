<template>
  <div v-if="results && leadershipStyles">
    <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
      <h3 class="page-title">{{ leadershipStyles.title }}</h3>
      <span v-if="hasAtLeastOneItem" class="pointer page-description" @click="toggleAllShow">
        {{ listsIsOpen ? 'Скрыть все' : 'Раскрыть все' }}
      </span>
    </div>

    <div class="row mt-2 justify-space-between">
      <div class="col-12">
        <h4 class="font-weight-600 success--text text-uppercase">{{ leadershipStyles.HIGHT.title }}</h4>
        <div class="mt-md-2">
          <v-expansion-panels v-if="leadershipStyles.HIGHT.leadership_styles.length"
                              v-model="showHeight"
                              multiple>
            <v-expansion-panel class="accordion"
                               v-for="(item,i) in leadershipStyles.HIGHT.leadership_styles" :key="i">
              <v-expansion-panel-header>
                <span class="text-primary font-weight-600">{{ item.title }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div v-else>Отсутствуют</div>
        </div>
      </div>

      <div class="col-12">
        <hr class="d-md-none mb-6">

        <h4 class="font-weight-600 warning--text text-uppercase">{{ leadershipStyles.MEDIUM.title }}</h4>
        <div class="mt-md-2">
          <v-expansion-panels v-if="leadershipStyles.MEDIUM.leadership_styles.length"
                              v-model="showMedium"
                              multiple>
            <v-expansion-panel class="accordion"
                               v-for="(item,i) in leadershipStyles.MEDIUM.leadership_styles" :key="i">
              <v-expansion-panel-header>
                <span class="text-primary font-weight-600">{{ item.title }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div v-else>Отсутствуют</div>
        </div>
      </div>

      <div class="col-12">
        <hr class="d-md-none mb-6">

        <h4 class="font-weight-600 error--text text-uppercase">{{ leadershipStyles.LOW.title }}</h4>
        <div class="mt-md-2">
          <v-expansion-panels v-if="leadershipStyles.LOW.leadership_styles.length"
                              v-model="showLow"
                              multiple>
            <v-expansion-panel class="accordion"
                               v-for="(item,i) in leadershipStyles.LOW.leadership_styles" :key="i">
              <v-expansion-panel-header>
                <span class="text-primary font-weight-600">{{ item.title }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div v-else>Отсутствуют</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ManagementStyleV2',
  data: () => ({
    showHeight: [],
    showMedium: [],
    showLow: [],
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    leadershipStyles() {
      return this.results.leadership_styles;
    },
    listsIsOpen() {
      return this.showHeight.length || this.showMedium.length || this.showLow.length;
    },
    hasAtLeastOneItem() {
      return this.leadershipStyles.HIGHT.leadership_styles.length
        || this.leadershipStyles.MEDIUM.leadership_styles.length
        || this.leadershipStyles.LOW.leadership_styles.length;
    },
  },
  methods: {
    toggleAllShow() {
      if (this.listsIsOpen) {
        this.showHeight = [];
        this.showMedium = [];
        this.showLow = [];
      } else {
        if (this.leadershipStyles.HIGHT.leadership_styles) {
          this.showHeight = this.leadershipStyles.HIGHT.leadership_styles.map((k, i) => i);
        }
        if (this.leadershipStyles.MEDIUM.leadership_styles) {
          this.showMedium = this.leadershipStyles.MEDIUM.leadership_styles.map((k, i) => i);
        }
        if (this.leadershipStyles.LOW.leadership_styles) {
          this.showLow = this.leadershipStyles.LOW.leadership_styles.map((k, i) => i);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  &::before {
    $size: 0.75em;
    content: '';
    display: inline-block;
    width: $size;
    height: $size;
    margin-right: 6px;
    background-color: currentColor;
    border-radius: 50%;
  }
}
</style>
