<template>
  <div v-if="results && competences">
    <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
      <h3 class="page-title">Компетенции</h3>
      <span class="pointer page-description" @click="toggleAllShow">
        {{listsIsOpen ? 'Скрыть все' : 'Раскрыть все'}}
      </span>
    </div>

    <div class="row mt-2 justify-space-between">
      <div v-if="competences.HIGHT" class="col-md-3">
        <h4 class="font-weight-600 success--text">Высокоразвитые</h4>
        <div class="mt-md-2">
          <v-expansion-panels v-model="showHeight" multiple>
            <v-expansion-panel class="accordion"
              v-for="(item,i) in competences.HIGHT.competences" :key="i">
              <v-expansion-panel-header>
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{item.description}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <div v-if="competences.MEDIUM" class="col-md-3">
        <hr class="d-md-none mb-6">

        <h4 class="font-weight-600 warning--text">Среднеразвитые</h4>
        <div class="mt-md-2">
          <v-expansion-panels v-model="showMedium" multiple>
            <v-expansion-panel class="accordion"
                               v-for="(item,i) in competences.MEDIUM.competences" :key="i">
              <v-expansion-panel-header>
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{item.description}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <div v-if="competences.LOW" class="col-md-3">
        <hr class="d-md-none mb-6">

        <h4 class="font-weight-600 error--text">Низкоразвитые</h4>
        <div class="mt-md-2">
          <v-expansion-panels v-model="showLow" multiple>
            <v-expansion-panel class="accordion"
                               v-for="(item,i) in competences.LOW.competences" :key="i">
              <v-expansion-panel-header>
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{item.description}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Competencies',
  data: () => ({
    showHeight: [],
    showMedium: [],
    showLow: [],
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    competences() {
      return this.results.competences;
    },
    listsIsOpen() {
      return this.showHeight.length || this.showMedium.length || this.showLow.length;
    },
  },
  methods: {
    toggleAllShow() {
      if (this.listsIsOpen) {
        this.showHeight = [];
        this.showMedium = [];
        this.showLow = [];
      } else {
        if (this.competences.HIGHT.competences) {
          this.showHeight = this.competences.HIGHT.competences.map((k, i) => i);
        }
        if (this.competences.MEDIUM.competences) {
          this.showMedium = this.competences.MEDIUM.competences.map((k, i) => i);
        }
        if (this.competences.LOW.competences) {
          this.showLow = this.competences.LOW.competences.map((k, i) => i);
        }
      }
    },
  },
};
</script>
