<template>
  <div class="_page">
    <div v-if="loading.candidate" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <BackBtn />

    <BreadCrumbs :items="breadcrumbs" />

    <h1 class="font-lg mb-5">{{reportInfo.name}}</h1>

    <div v-if="!$route.params.reportType">
      <ProfileTabReports />
    </div>

    <template v-if="$route.params.reportType==='main' && $store.getters['auth/isAllowed']('view_fullreport')">
      <PdfButton v-if="$store.getters['auth/isAllowed']('download_fullreport')"
        :loading="loading.pdf.index" @downloadPdf="loadPdfStd"/>
      <div class="profile-board mt-8 pt-2">
        <ProfileTabs />
        <component v-bind:is="selectedTab.component" :tabInfo="selectedTab"/>
      </div>
    </template>

    <template v-if="$route.params.reportType==='short' && $store.getters['auth/isAllowed']('view_shortreport')">
      <PdfButton v-if="$store.getters['auth/isAllowed']('download_shortreport')"
        :loading="loading.pdf.short" class="load-pdf mb-5" @downloadPdf="loadPdfShort($event)" />
      <ShortReport @switchToFull="console.log('switchToFull')" />
    </template>

    <template v-if="$route.params.reportType==='constructor' && isReportAllowed($route.query.test)">
      <PdfButton v-if="isReportDownloadAllowed($route.query.test)"
        :loading="loading.pdf.constructor" class="load-pdf mb-5" @downloadPdf="loadPdfConstructor($event)" />
      <ConstructorReport @switchToFull="console.log('switchToFull')" :testCode="$route.query.test"/>
    </template>

    <template v-if="$route.params.reportType==='psycho' && $store.getters['auth/isAllowed']('view_psychotypereport')">
      <PdfButton v-if="$store.getters['auth/isAllowed']('download_psychotypereport')"
        :loading="loading.pdf.psycho" class="load-pdf mb-5" @downloadPdf="loadPdfPsycho($event)" />
      <PsychoView />
    </template>

    <template v-if="$route.params.reportType==='reco' && $store.getters['auth/isAllowed']('view_recommendations')">
      <PdfButton v-if="$store.getters['auth/isAllowed']('download_recommendations')"
        :loading="loading.pdf.reco" class="load-pdf mb-5" @downloadPdf="loadPdfReco($event)" />
      <RecomendationView />
    </template>

    <template v-if="$route.params.reportType==='checklist' && $store.getters['auth/isAllowed']('view_riskfactor')">
      <PdfButton v-if="$store.getters['auth/isAllowed']('download_riskfactor')"
            :loading="loading.pdf.checklist" class="load-pdf mb-5" @downloadPdf="loadPdfCheckList($event)" />
      <CheckListView />
    </template>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileTabReports from '@/components/candidates/profile/ProfileTabReports';
import ProfileTabs from '@/components/profile/ProfileTabs';
import ComplianceReport from '@/components/profile/tabs/ComplianceReport';
import Risks from '@/components/profile/tabs/Risks';
import Scales from '@/components/profile/tabs/Scales';
import Credibility from '@/components/profile/tabs/Credibility';
import PeakScales from '@/components/profile/tabs/PeakScales';
import Competencies from '@/components/profile/tabs/Competencies';
import PersonalAbilities from '@/components/profile/tabs/PersonalAbilities';
import ManagementPotential from '@/components/profile/tabs/ManagementPotential';
import StressTolerance from '@/components/profile/tabs/StressTolerance';
import Anxiety from '@/components/profile/tabs/Anxiety.vue';
import MotivatorsAndDestructors from '@/components/profile/tabs/MotivatorsAndDestructors';
import TeamRoles from '@/components/profile/tabs/TeamRoles';
import ManagementStyle from '@/components/profile/tabs/ManagementStyleV2';
import Logic from '@/components/profile/tabs/Logic';
import ProfInterests from '@/components/profile/tabs/ProfInterests';
import PsychoView from '@/components/psycho/PsychoView';
import RecomendationView from '@/components/recomendations/RecomendationView';
import CheckListView from '@/components/recomendations/CheckListView';
import ShortReport from '@/components/shortReport/ShortReport';
import ConstructorReport from '@/components/constructorReport/ConstructorReport';
import PdfButton from '@/components/ui/PdfButton';
import TestEditorResult from '@/components/profile/tabs/TestEditorResult';

export default {
  name: 'Candidate',
  components: {
    PdfButton,
    ProfileTabs,
    ComplianceReport,
    Risks,
    Scales,
    Credibility,
    PeakScales,
    Competencies,
    PersonalAbilities,
    StressTolerance,
    MotivatorsAndDestructors,
    TeamRoles,
    ManagementStyle,
    Logic,
    ProfInterests,
    PsychoView,
    ShortReport,
    ConstructorReport,
    RecomendationView,
    CheckListView,
    ProfileTabReports,
    TestEditorResult,
    ManagementPotential,
    Anxiety,
  },
  props: ['userId'],
  data() {
    return {
    };
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  computed: {
    ...mapGetters({
      selectedTab: 'profile/tabs/selectedTab',
      candidate: 'users/candidate/candidate',
      loading: 'users/candidate/loading',
      ps_current: 'users/candidate/resultsPsycho',
      ps_types: 'users/candidate/psychoTypes',
      isReportAllowed: 'tests/isReportAllowed',
      isReportDownloadAllowed: 'tests/isReportDownloadAllowed',
    }),
    psExists() {
      return this.ps_current?.main;
    },
    psMain() {
      return this.ps_current?.main ? this.ps_types.find((el) => el.code === this.ps_current.main[0]) : null;
    },
    breadcrumbs() {
      if (!this.candidate) return [];
      let ret = [];
      if (this.isProfile) {
        ret = [
          { title: 'Личный кабинет', url: '/' },
          { title: 'Отчеты', url: '/reports' },
        ];
        if (this.$route.params.reportType) {
          ret.push({ title: this.reportInfo.name, url: `/reports/${this.$route.params.reportType}?test=${this.$route.query.test}` });
        }
      } else {
        ret = [
          { title: 'Кандидаты', url: '/candidates/' },
          { title: `${this.candidate.last_name} ${this.candidate.first_name}`, url: `/candidates/${this.candidate.id}` },
        ];
        if (this.$route.params.reportType) {
          ret.push({ title: this.reportInfo.name, url: `/candidates/${this.candidate.id}/reports/${this.$route.params.reportType}` });
        }
      }
      return ret;
    },
    reportInfo() {
      switch (this.$route.params.reportType) {
        case 'main': return { name: 'Полный отчет' };
        case 'short': return { name: 'Краткий отчет' };
        case 'psycho': return { name: 'Отчет респондента' };
        case 'reco': return { name: 'Отчет с рекомендациями' };
        case 'checklist': return { name: 'Поведенческие риски' };
        case 'constructor': return { name: 'Отчет' };
        default: return { name: 'Отчет' };
      }
    }
  },
  methods: {
    ...mapActions({
      loadCandidate: 'users/candidate/loadCandidate',
      loadCandidateResults: 'users/candidate/loadCandidateResults',
      resetStateCandidate: 'users/candidate/resetState',
      resetStateTabs: 'profile/tabs/resetState',
      loadCandidatePdf: 'users/candidate/loadCandidatePdf',
      loadCandidatePsychoPdf: 'users/candidate/loadCandidatePsychoPdf',
      loadCandidateRecomendationsPdf: 'users/candidate/loadCandidateRecomendationsPdf',
      loadCandidateCheckListPdf: 'users/candidate/loadCandidateCheckListPdf',
    }),
    downloadFile(resp) {
      if (resp.error) {
        this.$toast.error(`Ошибка: ${resp.error}`, '', { position: 'topRight' });
        return;
      }
      const link = document.createElement('a');
      link.setAttribute('download', true);
      link.setAttribute('href', resp.link);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async loadPdfStd(logo) {
      const resp = await this.loadCandidatePdf({ candidate: this.candidate.id, report_type: 'index', logo });
      this.downloadFile(resp);
    },
    async loadPdfShort(logo) {
      const resp = await this.loadCandidatePdf({ candidate: this.candidate.id, report_type: 'short', logo });
      this.downloadFile(resp);
    },
    async loadPdfConstructor(logo) {
      const resp = await this.loadCandidatePdf({
        candidate: this.candidate.id,
        report_type: 'constructor',
        logo,
        report_test: this.$route.query.test
      });
      this.downloadFile(resp);
    },
    async loadPdfPsycho(logo) {
      const resp = await this.loadCandidatePsychoPdf({ candidate: this.candidate.id, ps_code: this.psMain.code, logo });
      this.downloadFile(resp);
    },
    async loadPdfReco(logo) {
      const data = {
        candidate: this.candidate.id,
        report_type: 'original', // editable
        logo,
        target: 1
      };
      const resp = await this.loadCandidateRecomendationsPdf(data);
      this.downloadFile(resp);
    },
    async loadPdfCheckList(logo) {
      const data = {
        candidate: this.candidate.id,
        report_type: 'original', // editable
        target: 1,
        logo
      };
      const resp = await this.loadCandidateCheckListPdf(data);
      this.downloadFile(resp);
    },
  },
  async created() {
    await this.loadCandidate(this.userId);
    await this.loadCandidateResults(this.userId);
  },
  beforeDestroy() {
    this.resetStateCandidate();
    this.resetStateTabs();
  },
};
</script>
