<template>
  <div v-if="results && logic">
    <div class="row mt-2 mt-md-8">
      <div class="col-12">
        <h3 class="page-title">Логика</h3>
      </div>
    </div>

    <div v-for="result in logic.results" :key="result.code" class="row mt-2 mt-md-6">
      <div class="col-sm-5 col-md-4 col-lg-3">
        <div class="donut-scale">
          <DonutScale :value="result.value"/>
        </div>
      </div>
      <div class="col-sm-7 col-md-8 col-lg-9">
        <h3 class="page-title">{{result.title}}</h3>
        <div class="page-subtitle mt-2 mt-md-4">{{result.subtitle}}</div>
        <div class="page-description mt-2 mt-md-4">{{result.description}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DonutScale from '@/components/ui/DonutScale';
import { mapGetters } from 'vuex';

export default {
  name: 'Logic',
  components: {
    DonutScale,
  },
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    logic() {
      return this.results.logic;
    },
  },
};
</script>

<style scoped lang="scss">
.donut-scale {
  max-width: 320px;
}
</style>
