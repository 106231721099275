<template>
  <div v-if="results && profInterests && profInterests.length">
    <div class="row mt-2 mt-md-8">
      <div class="col-12">
        <h3 class="page-title">Карта интересов</h3>
      </div>

      <div class="col-md-9 mt-2 mt-md-8">
        <div>
          <Scale v-for="(scale, ind) in scales" :key="ind" :data="scale"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
          <h3 class="page-title">Характеристики</h3>
          <span class="pointer page-description" @click="toggleAllShow">
            {{listsIsOpen ? 'Скрыть все' : 'Раскрыть все'}}
          </span>
        </div>
      </div>
    </div>

    <div class="row border-bottom mt-2 pb-8">
      <div class="col-md-5 py-0">
        <v-expansion-panels v-model="show1" multiple>
          <v-expansion-panel class="accordion"
                             v-for="(item,i) in characteristics1" :key="i">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <img class="mr-3 icon-sm" src="@/assets/images/icons/rhombus.svg" :alt="item.title">
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-7">
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="col-md-5 offset-md-2 py-0">
        <v-expansion-panels v-model="show2" multiple>
          <v-expansion-panel class="accordion"
                             v-for="(item,i) in characteristics2" :key="i">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <img class="mr-3 icon-sm" src="@/assets/images/icons/rhombus.svg" :alt="item.title">
                <span class="text-primary font-weight-600">{{item.title}}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-7">
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scale from '@/components/ui/Scale';

export default {
  name: 'ProfInterests',
  components: {
    Scale,
  },
  data: () => ({
    show1: [],
    show2: [],
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    profInterests() {
      return this.results.prof_interests?.prof_interests?.prof_interests;
    },
    scales() {
      return this.profInterests.map((i) => ({ titleStart: i.title, value: i.points }));
    },
    characteristics1() {
      const count = Math.ceil(this.profInterests.length / 2);
      return this.profInterests.slice(0, count);
    },
    characteristics2() {
      const count = Math.ceil(this.profInterests.length / 2);
      return this.profInterests.slice(count);
    },
    listsIsOpen() {
      return this.show1.length || this.show2.length;
    },
  },
  methods: {
    toggleAllShow() {
      if (this.listsIsOpen) {
        this.show1 = [];
        this.show2 = [];
      } else {
        this.show1 = this.characteristics1.map((k, i) => i);
        this.show2 = this.characteristics2.map((k, i) => i);
      }
    },
  },
};
</script>

<style scoped>

</style>
