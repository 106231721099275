<template>
  <div v-if="results && results.personal_abilities && results.personal_abilities.competences">
    <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
      <h3 class="page-title">{{ personal_abilities.title }}</h3>
    </div>
    <div class="compet-title-desc">{{ personal_abilities.description }}</div>
    <template v-if="allItems && allItems.length">
      <div class="compet-titles">
        <div>Характеристика</div>
        <div>Степень предрасположенности</div>
      </div>
      <PersonalAbilityRow v-for="(item,i) in allItems" :key="i" :item="item" />
    </template>
    <template v-else-if="topItems && topItems.length">
      <div class="compet-row__title">ВЫСОКОРАЗВИТЫЕ</div>
      <div class="compet-titles">
        <div>Характеристика</div>
        <div>Степень предрасположенности</div>
      </div>
      <PersonalAbilityRow v-for="(item,i) in topItems" :key="i" :item="item" />

      <div class="compet-row__title">НИЗКОРАЗВИТЫЕ</div>
      <div class="compet-titles">
        <div>Характеристика</div>
        <div>Степень предрасположенности</div>
      </div>
      <PersonalAbilityRow v-for="(item,i) in bottomItems" :key="i" :item="item" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonalAbilityRow from './helpers/PersonalAbilityRow';

export default {
  name: 'PersonalAbilities',
  components: {
    PersonalAbilityRow
  },
  data: () => ({
  }),
  props: {
    shortReport: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    personal_abilities() {
      return this.results.personal_abilities ? this.results.personal_abilities : { title: '', description: '' };
    },
    sortedItems() {
      const arr = this.personal_abilities?.competences?.length ? [...this.personal_abilities.competences] : [];
      return arr.sort((a, b) => b.level - a.level);
    },
    allItems() {
      return !this.shortReport || this.sortedItems.length <= 10 ? this.sortedItems : null;
    },
    topItems() {
      return this.shortReport && this.sortedItems.length > 10 ? this.sortedItems.slice(0, 5) : null;
    },
    bottomItems() {
      return this.shortReport && this.sortedItems.length > 10 ? this.sortedItems.slice(-5) : null;
    },
  },
  methods: {
  },
};
</script>

<style>
.compet-titles {
    display: flex;
    font-weight: bold;
    justify-content: space-around;
    margin: 20px 0;
}

.compet-title-desc {
    margin: 20px 0;
}
</style>
