<template>
    <div class="profile-board test-editor-results">
      <h2 v-if="(loading.results || loading.candidate)">Загрузка...</h2>
      <h2 v-else-if="!res">Тест не завершен</h2>

        <!-- <h1 class="rep-top-title">Профессиональный навигатор</h1> -->

        <div v-else class="row mt-2 mt-md-8">
            <div class="col-12 pa-3">
                <h3 class="rep-test-title">{{ testData.name }}</h3>
                <hr class="mt-7 mb-4">

                    <!-- <pre>{{ tabInfo.testData }}</pre> -->
                    <div class="profnavigator-list">
                        <div v-for="(prof, indProf) in positions(testData)" :key="indProf" class="profnavigator-item">
                            <!-- <Scale10Blocks :value="prof.value"></Scale10Blocks> -->
                            <div v-for="block in getScaleLimit" :key="block" class="profnavigator-item-block"
                                :class="getBlockClass(prof, indProf, block)" />
                            <div class="profnavigator-item-value">{{ getProfValue(prof) }}</div>
                            <div class="profnavigator-item-title">{{ prof.block ? prof.block.title : "Без названия" }}</div>
                        </div>
                    </div>
                    <h4 class="desc-title">{{ testData.test_results_title ? testData.test_results_title : '' }} </h4>
                    <p v-if="testData.test_results_description">
                        {{ testData.test_results_description }}
                    </p>
                    <!-- <p v-else>
                        Ниже представлены 3 ключевых направления деятельности, к которым Вы проявили наибольший интерес. Развиваясь в данных направлениях, Вы можете добиться большего успеха, так как данные направления деятельности Вас будут мотивировать в Вашей работе
                        Для успешного освоения профессий Вам может понадобиться пройти дополнительное обучение, обратитесь за информацией к консультанту или работодателю.
                    </p> -->
                    <div v-for="(prof, indProf) in top3positions(testData)" :key="indProf" class="desc-item">
                        <div class="desc-item-title">{{ prof.block ? prof.block.title : "Без названия" }}</div>
                        <div class="desc-item-text" v-html="prepareText(prof.text)" />
                        <!-- Рекомендации на саму платформу вообще не идут - только описание -->
                        <!-- <template v-if="prof.recommendation">
                            <div class="desc-item-title">Рекомендации</div>
                            <div class="desc-item-text" v-html="prepareText(prof.recommendation)" />
                        </template> -->
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
/* eslint-disable */
import Scale10Blocks from "../ui/Scale10ColorBlocks.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Scale10Blocks
    },
    data() {
        return {
        };
    },
    props: ['testCode'],
    computed: {
        ...mapGetters({
            user: 'auth/user',
            res: 'users/candidate/results',
            loading: 'users/candidate/loading',
            loadingCandidate: 'users/candidate/loading',
        }),
        getScaleLimit() {
            return 10;
        },
        testData() {
            return this.res?.constructor_test_results && this.res.constructor_test_results[this.testCode] 
                ? this.res.constructor_test_results[this.testCode] 
                : { name: "Отчет не найден", blocks: [] }
        },
        candidateId() {
            return this.user.user_type === CANDIDATE ? this.user.id : this.$route.params.id;
        },
    },
    methods: {
        ...mapActions({
        }),
        getTestTitle(key) {
            const testDesc = this.res?.general_info?.tests.find(el => el.test_code===key)
            return testDesc ? testDesc.title : "Профнавигатор"
        },
        positions(testData) {
            const arr = [...testData.blocks]
            arr.sort((a, b) => b?.value - a?.value)
            return arr
        },
        top3positions(testData) {
            if (this.testCode==='MOSTURISM')
                return this.positions(testData).slice(0, 3)
            else
                return this.positions(testData)
        },
        getProfTestTitle(key) {
            const testDesc = this.res?.general_info?.tests.find(el => el.test_code===key)
            return testDesc ? testDesc.title : "Профнавигатор"
        },
        getProfTests(key) {
            this.res?.constructor_test_results[key].sort((a, b) => b?.value - a?.value)
            return this.res?.constructor_test_results[key].slice(0, 3)
        },
        getProfValue(item) {
            let v = Math.round(item.value)
            if (v < 0) v = 0;
            if (v > 10) v = 10;
            return v
        },
        getBlockClass(prof, indProf, indBlock) {
            const v = this.getProfValue(prof)
            let ret = 'profnavigator-item-block'
            if (indBlock <= v) 
                ret = indProf === 0 ? 'profnavigator-item-block-dark' : 'profnavigator-item-block-blue'
            if (this.getScaleLimit > 12)
                ret += ' profnavigator-item-block-dense'
            return ret
        },
        prepareText(text) {
            const lines = text.split('\n');
            let html = '';
            lines.forEach(line => {
                const spaces = line.match(/^\s*/)[0].length;
                const indent = '&nbsp;&nbsp;'.repeat(spaces);
                html += `<p>${indent}${line.trim()}</p>`;
            });

            return html;
        }

    },
};
</script>


<style scoped lang="scss">

.rep-top-title {
  font-size: 28px;
  color: #4f62a4;
}

.rep-test-title  {
  font-size: 24px;
}


</style>
