<template>
    <div class="compet-row">
      <div class="compet-row__1">
        <div class="compet-row__title">
          {{item.title}}
        </div>
        <div class="compet-row__blocks">
           <Scale10Blocks :value="item.level" />
        </div>
      </div>
      <div class="compet-row__desc">
        {{item.description}}
      </div>
    </div>
</template>

<script>
import Scale10Blocks from '@/components/ui/Scale10ColorBlocks.vue';

export default {
  name: 'PersonalAbilityRow',
  components: {
    Scale10Blocks
  },
  props: ['item'],
  data: () => ({
  }),
};
</script>

<style>
.compet-row {
    border-top: 1px solid #ccc;
    padding: 15px 20px;
}

.compet-row__1 {
    display: flex;
}

.compet-row__title {
    flex: 50% 0 0;
    font-weight: bold;
    padding-top: 20px;
}

.compet-row__blocks {
    flex: 50% 0 0;
}

.compet-row__desc {
    margin-top: 10px;
}
</style>
