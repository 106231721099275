import { render, staticRenderFns } from "./Anxiety.vue?vue&type=template&id=7939d568&scoped=true"
import script from "./Anxiety.vue?vue&type=script&lang=js"
export * from "./Anxiety.vue?vue&type=script&lang=js"
import style0 from "./Anxiety.vue?vue&type=style&index=0&id=7939d568&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7939d568",
  null
  
)

export default component.exports