<script>
import { Radar } from 'vue-chartjs';

export default {
  name: 'RadarChart',
  extends: Radar,
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        legend: {
          display: false,
        },
        scale: {
          ticks: {
            // max: 100,
            // min: 0,
          },
          pointLabels: {
            fontSize: Math.min((window.innerWidth / 38), 15),
            fontFamily: 'Montserrat',
            fontColor: '#25325F',
          },
          angleLines: {
            color: '#C4C9D7',
          },
          gridLines: {
            color: 'transparent',
          },
        },
      }),
    },
  },
  mounted() {
    const { scale } = this.options;
    const { ticks } = this.options.scale;
    const arr = this.data?.datasets?.[0]?.data ?? [];
    ticks.min = Math.min(...arr);

    this.renderChart(this.data, { ...this.options, scale });
  },
};
</script>
