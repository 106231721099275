<template>
    <div class="profile-board short-report">
      <h2 v-if="!psExists && (loading.results || loading.candidate)">Загрузка...</h2>
      <h2 v-else-if="!psExists">Тест не завершен</h2>
      <div v-if="psExists && res">

        <h1 class="rep-top-title">Отчёт. Основные выводы</h1>

        <div v-if="res.general_info.reliability === 0" class="reliability reliability-0">Не достоверный</div>
        <div v-if="res.general_info.reliability === 1" class="reliability reliability-1">Сомнительно достоверный</div>
        <div v-if="res.general_info.reliability === 2" class="reliability reliability-2">Достоверный</div>

        <!-- Характерное для респондента поведение -->
        <div v-if="res.scales && res.scales.peak_scales && res.scales.peak_scales.scales && res.scales.peak_scales.scales.length">
          <h3 class="rep-title">Характерное для респондента поведение</h3>
          <div class="row rep-small-text">
            <div class="col-6" v-if="res.scales.peak_scales.scales.length>0">
                <div class="positive-behaviour">{{res.scales.peak_scales.scales[0].positive_behaviour}}</div>
            </div>
            <div class="col-6" v-if="res.scales.peak_scales.scales.length>1">
                <div class="positive-behaviour">{{res.scales.peak_scales.scales[1].positive_behaviour}}</div>
            </div>
          </div>
          <div class="row rep-small-text">
            <div class="col-6" v-if="res.scales.peak_scales.scales.length>0">
                <div class="negative-behaviour">{{res.scales.peak_scales.scales[0].negative_behaviour}}</div>
            </div>
            <div class="col-6" v-if="res.scales.peak_scales.scales.length>1">
                <div class="negative-behaviour">{{res.scales.peak_scales.scales[1].negative_behaviour}}</div>
            </div>
          </div>
        </div>

        <!-- Cоответствие профессии -->

        <div v-if="compliance" class="compliance">
          <h3 class="rep-title">Cоответствие профессии</h3>
          <div class="row">
            <DonutScale :value="compliance.value" fontSize="1rem" class="compliance-donut" />
            <div class="compliance-desc">Соответствует профессии {{compliance.name}}</div>
          </div>
        </div>

        <!-- Стиль коммуникации, характерный для респондента -->

        <template v-if="res.leadership_styles && res.leadership_styles.HIGHT && res.leadership_styles.HIGHT.leadership_styles.length">
          <h3 class="rep-title">Стиль коммуникации, характерный для респондента</h3>
          <div class="rep-subtitle rep-mb-2">{{res.leadership_styles.HIGHT.leadership_styles[0].title}}</div>
          <div class="rep-small-text">{{res.leadership_styles.HIGHT.leadership_styles[0].description}}</div>
        </template>

        <!-- Сильные стороны и зоны роста -->

        <template v-if="res.competences && (res.competences.HIGHT || res.competences.LOW)">
          <h3 class="rep-title">Сильные стороны и зоны роста</h3>
          <div class="row">
            <div v-if="res.competences.HIGHT" class="col-6">
              <h4 class="rep-subtitle upper rep-green">Сильные стороны</h4>
              <div v-for="(item,i) in res.competences.HIGHT.competences" :key="i" class="marker marker-green rep-ml-3 rep-mt-2">
                {{item.title}}
              </div>
           </div>
           <div v-if="res.competences.LOW" class="col-6">
              <h4 class="rep-subtitle upper rep-red">Зоны роста</h4>
              <div v-for="(item,i) in res.competences.LOW.competences" :key="i" class="marker marker-red rep-ml-3 rep-mt-2">
                {{item.title}}
              </div>
           </div>
          </div>
        </template>

        <!-- Топ-3 профессиональных интереса -->

        <div v-if="res && profInterests">
          <h3 class="rep-title">Топ-3 профессиональных интереса</h3>
          <div class="gray-hint rep-small-text">Профессиональные интересы указаны в порядке убывания их значимости для респондента</div>
          <div class="row">
            <div v-for="(p, ind) in profInterests" :key="ind" class="col-12 col-sm-6 col-md-4 rep-px-8">
                <div class="rep-subtitle-2 rep-mb-2">{{ind+1}}. {{p.title}}</div>
                <div class="rep-small-text">{{p.description}}</div>
            </div>
            </div>
        </div>

        <!-- Профнавигатор -->

        <!-- <div v-if="res && res.constructor_test_results">
            <h3 class="rep-title">Топ-3 профессиональных интереса</h3>
            <div v-for="(profTest, profTestKey) in res.constructor_test_results" :key="profTestKey">
                <h3 class="rep-title">{{ getProfTestTitle(profTestKey) }}</h3>

                <div class="gray-hint rep-small-text">Профессиональные интересы указаны в порядке убывания их значимости для респондента</div>
                <div class="row">
                    <div v-for="(p, ind) in getProfTests(profTestKey)" :key="ind" class="col-12 col-sm-6 col-md-4 rep-px-8">
                        <div class="rep-subtitle-2 rep-mb-2">{{ind+1}}. {{p.block.title}}</div>
                        <div class="rep-small-text" v-html="p.text"></div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Поведенческие риски и поведение респондента, на которое нужно обратить внимание -->

        <template v-if="(res.risk_factors && res.risk_factors.factors.length)
            || (res.attentionFactor && res.attentionFactor.factors && res.attentionFactor.factors.length)"
        >
          <h3 class="rep-title">Поведенческие риски, на которые нужно обратить внимание</h3>
          <div class="risks">
            <ul>
                <template v-if="res.risk_factors">
                  <li v-for="(f, ind) in res.risk_factors.factors" :key="ind">{{capitalizeFirstLetter(f.title)}}</li>
                </template>
                <template v-if="res.attantion_factor && res.attantion_factor.factors" >
                  <li v-for="(f, ind) in res.attantion_factor.factors" :key="ind+100">{{capitalizeFirstLetter(f.title)}}</li>
                </template>
            </ul>
          </div>
        </template>

        <!-- Структура интеллекта -->

         <template v-if="res.logic">
            <h3 class="rep-title">Структура интеллекта</h3>
            <div class="row logic">
                <div v-for="result in res.logic.results" :key="result.code" class="col-12 col-sm-6 col-md-4 rep-px-8">
                   <div class="donut-scale">
                       <DonutScale :value="result.value" fontSize="2rem" />
                    </div>
                    <div class="">
                        <h3 class="rep-subtitle upper">{{result.title}}</h3>
                        <div class="rep-subtitle-2" :style="{color: colorLevel(result.value)}">{{result.subtitle}}</div>
                        <div class="rep-small-text">{{result.description}}</div>
                    </div>
                </div>
            </div>
         </template>

         <!-- Стрессоустойчивость -->

        <template v-if="res.stress_tolerance">
          <h3 class="rep-title">Стрессоустойчивость</h3>
          <StressTolerance  hideTitle />
        </template>

         <!-- Тревожность -->

         <template v-if="res.anxiety">
          <h3 class="rep-title">{{res.anxiety.title}}</h3>
          <Anxiety  hideTitle />
        </template>

        <!-- Личностные способности -->

         <template v-if="res.personal_abilities">
          <PersonalAbilities  shortReport />
        </template>

         <!-- Управленческий потенциал -->

         <template v-if="res.management_potential">
          <ManagementPotential shortReport />
        </template>

        <!-- Роли в команде -->

        <div v-if="teamRoles.last" class="motiv">
          <h3 class="rep-title">Роли в команде</h3>
          <div class="rep-subtitle rep-mt-2 rep-mb-2">Наиболее характерная роль в команде</div>
          <div class="marker rep-subtitle-2 rep-green rep-mb-1 rep-ml-3">{{teamRoles.first.title}}</div>
          <div class="rep-small-text rep-ml-3">{{teamRoles.first.description}}</div>
          <div class="rep-subtitle rep-mt-2 rep-mb-2">Наименее характерная роль в команде</div>
          <div class="marker rep-subtitle-2 rep-red rep-mb-1 rep-ml-3">{{teamRoles.last.title}}</div>
          <div class="rep-small-text rep-ml-3">{{teamRoles.last.description}}</div>
        </div>

        <!-- Что мотивирует и демотивирует респондента в работе -->

        <div v-if="res.motivators_and_destructors" class="motiv">
          <h3 class="rep-title">Что мотивирует и демотивирует респондента в работе</h3>
          <div class="gray-hint rep-small-text">Здесь даны факторы, которые мотивируют и демотивируют респондента. Помогают нам понять,
            как поддерживать респондента и вовлекать его в рабочие задачи и, наоборот, что делать не стоит,
            чтобы не снижать его интерес и желание
          </div>
          <div class="motiv-row">
             <div class="motiv-col rep-px-8 rep-subtitle upper rep-green">Мотиваторы</div>
             <div class="motiv-col rep-px-8 rep-subtitle upper rep-red">Демотиваторы</div>
          </div>
          <div v-for="ind of 3" :key="ind">
            <div v-if="res.motivators_and_destructors.motivators.motivators[ind-1]
                || res.motivators_and_destructors.destructors.destructors[ind-1]"
                class="motiv-row"
            >
                <div class="motiv-col">
                    <template v-if="res.motivators_and_destructors.motivators.motivators[ind-1]">
                        <div class="marker rep-subtitle-2 rep-green">
                            {{ind}}. {{res.motivators_and_destructors.motivators.motivators[ind-1].title}}
                        </div>
                        <div class="rep-small-text">
                            {{res.motivators_and_destructors.motivators.motivators[ind-1].description}}
                        </div>
                    </template>
                    <div class="motiv-border" />
                </div>
                <div class="motiv-col">
                    <template v-if="res.motivators_and_destructors.destructors.destructors[ind-1]">
                        <div class="marker rep-subtitle-2 rep-red">
                            {{res.motivators_and_destructors.destructors.destructors[ind-1].title}}
                        </div>
                        <div class="rep-small-text">
                            {{res.motivators_and_destructors.destructors.destructors[ind-1].description}}
                        </div>
                    </template>
                    <div class="motiv-border" />
                   </div>
                </div>
            </div>
        </div>

        <div class="mt-6 to-full-report rep-small-text">
           Детальную информацию по каждому блоку отчета вы можете посмотреть во вкладке “<a  @click="toFullReport">Полный отчет</a>”
        </div>
      </div>

      <MessageDialog v-model="showFullReportWarning" text="Услуга недоступна. Обратитесь к менеджеру за подключением." />
    </div>
</template>

<script>
/* eslint-disable */
import PdfButton from '@/components/ui/PdfButton';
import StressTolerance from '@/components/profile/tabs/StressTolerance';
import Anxiety from '@/components/profile/tabs/Anxiety.vue';
import PersonalAbilities from '@/components/profile/tabs/PersonalAbilities';
import ManagementPotential from '@/components/profile/tabs/ManagementPotential';
import MessageDialog from '@/components/ui/MessageDialog';
import { mapActions, mapGetters } from "vuex";
import DonutScale from '@/components/ui/DonutScale';
import { CANDIDATE } from "@/common/constants/userTypes";
import { TESTING_STATUSES } from '@/common/constants/testingStatuses';

export default {
    components: {
        DonutScale, StressTolerance, PdfButton, MessageDialog, PersonalAbilities, ManagementPotential, Anxiety
    },
    data() {
        return {
            showFullReportWarning: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            res: 'users/candidate/results',
            loading: 'users/candidate/loading',
            loadingCandidate: 'users/candidate/loading',
        }),

        psExists() {
            return this.res?.scales || this.res?.motivators_and_destructors || this.res?.stress_tolerance || this.res?.logic || this.res?.constructor_test_results
            const tests = this.res?.general_info?.tests
            if (tests) {
                const test = tests.find(el => el.test_code === 'MMPI')
                if (test && test.status_code === TESTING_STATUSES.COMPLETED) {
                    return true;
                }
            }
            return false;
        },
        candidateId() {
            return this.user.user_type === CANDIDATE ? this.user.id : this.$route.params.id;
        },
        compliance () {
            if (this.res.compliance) {
                let pos = { ...this.res.compliance.candidate_position  }
                if (this.res.compliance.positions)
                    this.res.compliance.positions.forEach(el => {
                        if (+el.value > +pos.value)
                            pos = { ...el }
                    });
                return {
                    name: pos.name,
                    value: Math.round(+pos.value * 100)
                }

            }
            return null
        },
        teamRoles() {
            let roles = this.res.team_roles_normalized?.team_roles?.team_roles;
            if (roles && roles.length > 0) {
                roles = [...roles].sort((a, b) => b.points - a.points);
                return {
                    first: roles[0],
                    last: roles[roles.length-1],
                }
            }
            return { first: null, last: null };
        },
        profInterests() {
            let pi = this.res?.prof_interests?.prof_interests?.prof_interests
            if (pi && pi.length > 0)
                return [...pi].sort((a, b) => b.points - a.points).slice(0, 3);
            return 0;
        }
    },
    methods: {
        ...mapActions({
        }),
        getProfTestTitle(key) {
            const testDesc = this.res?.general_info?.tests.find(el => el.test_code===key)
            return testDesc ? testDesc.title : "Профнавигатор"
        },
        getProfTests(key) {
            this.res?.constructor_test_results[key].sort((a, b) => b?.value - a?.value)
            return this.res?.constructor_test_results[key].slice(0, 3)
        },
        capitalizeFirstLetter(str) {
          if (!str) return ''; 
          return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },
        toFullReport() {
            if (this.$store.getters['auth/isAllowed']('view_fullreport')) {
                this.$emit('switchToFull');
            } else {
                this.showFullReportWarning = true;
            }
        },
        classLevel(val) {
            if (val >= 80) return 'success--text';
            else if (val >= 50) return 'warning--text';
            else return 'error--text';
        },
        colorLevel(value) {
            if (value > 0 && value < 25)  return '#FF6E6D';
            if (value >= 25 && value < 50) return '#FF9900';
            if (value >= 50 && value < 75) return '#FFCD1A';
            if (value >= 75) return '#94D727';
            return '#DEE1EA';
        },
    },
};
</script>


<style scoped lang="scss">

.short-report {
  position: relative;
}

.rep-title, ::v-deep .page-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #484848;
    margin: 40px 0 20px 0;
}

.rep-subtitle {
    font-weight: 600;
    font-size: 16px;
    margin-top: 8px;
}

.upper { text-transform: uppercase ; }
.rep-ml-3 { margin-left: 24px; }
.rep-mb-1 { margin-bottom: 4px; }
.rep-mb-2 { margin-bottom: 8px; }
.rep-mt-2 { margin-top: 8px; }
.rep-px-8 { padding: 0 32px; }
.rep-subtitle-2 {
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
}

::v-deep .page-description {
    font-weight: 400;
    font-size: 16px;
}

.rep-small-text {
    font-weight: 400;
    font-size: 14px;
}

.rep-donut {
    width: 100px;
    height: 100px;
}

.rep-green { color: #58CA30; }
.rep-red { color:  #FF6E6D; }

.marker {
    position: relative;
}

.marker:before {
    content: "";
    position: absolute;
    top: 6px;
    left: -20px;
    width: 8px;
    height: 8px;
    border: 3px solid #18a0fb;
    display: inline-block;
    transform: rotate(45deg);
    margin-right: 5px;
}
.marker.rep-red:before, .marker.marker-red:before { border: 3px solid #FF6E6D; }
.marker.rep-green:before, .marker.marker-green:before {  border: 3px solid #58CA30; }

.gray-hint {
    line-height: 130%;
    color: #484848;
    padding: 10px 16px;
    background: #F5F5F5;
    border-radius: 5px;
    margin-bottom: 20px;
}

.compliance {
.compliance-donut {
    padding: 0 30px;
    width: 50%;
    justify-content: center;
    max-width: 180px;
}
.compliance-desc {
    display: flex;
    width: 50%;
    align-items: center;
}

}

.risks {
    position: relative;
    background: #FFF2F2;
    border-radius: 5px;
    padding: 13px 13px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #484848;
    ul:before {
    content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 5px;
        background: #F58E8E;
        border-radius: 5px 0px 0px 5px;
    }
}


.logic {
.donut-scale {
    margin: 0 auto;
    // width: 50%;
    width: 150px;
}
.low { color: #F1B61F; }
.average { color: #F1B61F; }
.high { color: #F1B61F; }
}


.motiv {
    .motiv-row {
        display: flex;
    }
    .motiv-col {
    width: 50%;
    padding: 0px 10px 10px 20px;
    height: auto;
    position: relative;
    }
    .motiv-border {
    border-bottom: 2px solid #ddd;
    position: absolute;
    left: 0;
    right: 30px;
    bottom: 0;
    height: 20px;
    }
}

.to-full-report {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 16px 24px;
    font-weight: 600;
    text-align: center;
    color: #484848;
}


.rep-top-title {
    font-weight: 800;
    font-size: 40px;
    text-transform: uppercase;
    color: #4F62A4;
}

.reliability {
    padding: 4px 16px;
    display: inline-block;
    margin: 10px 0;
}
.reliability-0 {
    background: #ffe4e4;
    border: 1px solid  #FF6E6D;
    color: #FF6E6D;
}
.reliability-1 {
    background: #fefdec;
    border: 1px solid #dbcc05;
    color: #dbcc05;
}
.reliability-2 {
    background: #F0FFC3;
    border: 1px solid #94D727;
    color: #94D727;
}

.positive-behaviour {
    border-left: 3px solid #94D727;
    padding-left: 10px;
}

.negative-behaviour {
    border-left: 3px solid  #FF6E6D;
    padding-left: 10px;

}

.load-pdf {
  position: absolute;
  top: 40px;
  right: 36px;
}
</style>
